import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Navbar from "./Routes/Navbar";
import Home from "./Routes/Home";
import Team from "./Routes/Team";
import About from "./Routes/About";
import Contact from "./Routes/Contact";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navbar />}>
                    <Route index element={<Home />} />
                    <Route path="about" element={<About />} />
                    <Route path="contact" element={<Contact />} />
                </Route>
                {/* <Route path="/" element={<Navbar />}>
                    <Route index element={<Team />} />
                </Route> */}
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);
