import "./LaunchScreen.css";
import React, { useEffect, useLayoutEffect, useRef } from "react";
import { gsap, toArray } from "gsap";

export default function LaunchScreen(props) {
    const containerRef = useRef();
    const textRef = useRef();
    const imageRef = useRef();
    // const launchTL = useRef(gsap.timeline());
    // console.log(launchTL);

    // const endAnimation = () => {
    //     launchTL.current.kill();
    //     let endLaunchTL = gsap.timeline();
    //     endLaunchTL
    //         .to(imageRef.current, {
    //             boxShadow:
    //                 "0 0 0px 0px #000, 0 0 0px 0px #000, 0 0 0px 0px #000",
    //             duration: 1,
    //             ease: "power2.inOut",
    //         })
    //         .to(
    //             textRef.current,
    //             {
    //                 opacity: 0,
    //                 duration: 0.2,
    //             },
    //             "<0.4"
    //         )
    //         .to(
    //             imageRef.current,
    //             {
    //                 scale: 20,
    //                 duration: 1,
    //                 ease: "power2.in",
    //                 marginLeft: "25px",
    //             },
    //             ">0.1"
    //         )
    //         .to(
    //             imageRef.current,
    //             {
    //                 opacity: 0,
    //                 duration: 1,
    //             },
    //             "<0.3"
    //         )
    //         .to(
    //             containerRef.current,
    //             {
    //                 opacity: 0,
    //                 duration: 1,
    //             },
    //             "<0.5"
    //         );
    // };

    // useEffect(() => {
    //     if (!props.load) {
    //         endAnimation();
    //     }
    // }, [props]);

    useLayoutEffect(() => {
        let launchTL = gsap.timeline({ delay: 0.3, onComplete: props.load });

        launchTL
            .fromTo(
                textRef.current,
                { opacity: 0 },
                { opacity: "1", duration: 0.3, ease: "power2.in" }
            )
            .fromTo(
                imageRef.current,
                { opacity: 0, boxShadow: "none", scale: 1, marginLeft: "0" },
                {
                    opacity: "1",
                    duration: 1,
                    boxShadow:
                        "0 0 60px 10px #fff, 0 0 80px 35px rgb(255, 0, 0), 0 0 160px 50px rgb(0, 132, 255)",
                },
                "<"
            )
            .to(imageRef.current, {
                duration: 2,
                // repeat: 1,
                yoyo: true,
                boxShadow:
                    "0 0 60px 10px #fff, 0 0 80px 35px rgb(179, 255, 0),0 0 160px 50px rgb(153, 0, 255)",
                ease: "power2.inOut",
            })
            .to(imageRef.current, {
                boxShadow:
                    "0 0 0px 0px #000, 0 0 0px 0px #000, 0 0 0px 0px #000",
                duration: 1,
                ease: "power2.inOut",
            })
            .to(
                textRef.current,
                {
                    opacity: 0,
                    duration: 0.2,
                },
                "<0.4"
            )
            .to(
                imageRef.current,
                {
                    scale: 0,
                    duration: 0.3,
                    ease: "power2.in",
                    marginLeft: "25px",
                },
                "<"
            )
            .to(
                imageRef.current,
                {
                    opacity: 0,
                    duration: 1,
                },
                "<0.3"
            )
            .to(
                containerRef.current,
                {
                    opacity: 0,
                    duration: 1,
                },
                "<"
            );
    }, []);

    return (
        <div className="launch-container" ref={containerRef}>
            <img
                ref={imageRef}
                id="launch-img"
                src={require("../Images/vivid_film.png")}
                alt="vivid logo"
            />
            <span id="launch-text" ref={textRef}>
                VIVID
            </span>
        </div>
    );
}
