import "./MemberCard.css";

const MemberCard = (props) => {
    return (
        <div className="membercard-container">
            <img src={props.img} alt="member pic" className="membercard-img" />
            <div className="member-main-info">
                <div className="member-name">{props.name}</div>
                <div className="member-position">{props.role}</div>
            </div>
            <div className="member-secondary-info">
                <div className="ability">{props.description}</div>
            </div>
        </div>
    );
};

export default MemberCard;
