import "./Contact.css";

import React, { useLayoutEffect, useRef } from "react";
import gsap from "gsap";

export default function Contact() {
    const contactCnt = useRef();

    useLayoutEffect(() => {
        gsap.fromTo(
            contactCnt.current,
            { opacity: 0 },
            { opacity: 1, duration: 0.5 }
        );
    }, []);
    return (
        <div id="contact-container" ref={contactCnt}>
            <div id="inq">For Inquiries</div>{" "}
            <a href="mailto:panidara@ualberta.ca" id="email">
                VIVIDuofa@gmail.com
            </a>
        </div>
    );
}
