import "./Navbar.css";

import { Link, Outlet } from "react-router-dom";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import LaunchScreen from "../Components/LaunchScreen";

const Navbar = () => {
  const [complete, setComplete] = useState(false);
  // const [logo, setLogo] = useState(require("../Images/Asset_620x.png"));
  let mDiv = useRef();
  let vidList = useRef();
  let hamb = useRef();
  let overl = useRef();

  const launchComplete = () => {
    // vidList.current[0].play();
    setComplete(true);
  };

  const toggleSidebar = () => {
    if (hamb.current.classList.contains("show")) {
      hamb.current.classList.remove("show");
      overl.current.classList.remove("show");
    } else {
      hamb.current.classList.add("show");
      overl.current.classList.add("show");
    }
  };

  const closeSidebar = () => {
    hamb.current.classList.remove("show");
    overl.current.classList.remove("show");
  };

  return (
    <div id="main-container">
      <div id="overlay" ref={overl} onClick={toggleSidebar}></div>
      {!complete && <LaunchScreen load={launchComplete} />}
      <div id="navbar">
        <Link to="/" id="main-logo" className="link">
          <img
            src={require("../Images/Asset_620x.png")}
            id="main-img"
            alt="vivid-logo"
          />
          {/* <img
            src={require("../Images/colortext.png")}
            id="main-img-secondary"
            alt="vivid-logo"
          /> */}
        </Link>
        <div id="links-right">
          <Link to="/" id="about-link" className="link">
            Portfolio
          </Link>
          <Link to="/about" id="about-link" className="link">
            About
          </Link>
          <Link to="/contact" id="about-link" className="link">
            Contact
          </Link>
        </div>
        <button id="hamburger-links" onClick={toggleSidebar}>
          <img src={require("../Images/more.png")} alt="hamburger-menu" />
        </button>
      </div>
      <div id="sideMenu" ref={hamb}>
        <button onClick={closeSidebar}>
          <img src={require("../Images/close.png")} alt="close" />
        </button>
        <div id="sidemenuNav">
          <Link to="/" className="link" onClick={closeSidebar}>
            Portfolio
          </Link>
          <Link to="/about" className="link" onClick={closeSidebar}>
            About
          </Link>
          <Link to="/contact" className="link" onClick={closeSidebar}>
            Contact
          </Link>
        </div>
      </div>
      {complete && <Outlet />}
    </div>
  );
};

export default Navbar;
