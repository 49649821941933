import "./About.css";
import React, { useLayoutEffect, useRef, useState } from "react";
import MemberCard from "../Components/MemberCard";
import { gsap } from "gsap";

export default function About() {
  const aboutCnt = useRef();

  useLayoutEffect(() => {
    gsap.fromTo(
      aboutCnt.current,
      { opacity: 0 },
      { opacity: 1, duration: 0.5 }
    );
  }, []);

  return (
    <div id="about-container" ref={aboutCnt}>
      <div id="info-container">
        <div id="about-header">
          Hi, we're <span className="bold">VIVID</span>.
        </div>
        <div className="about-paragraph">
          A videography club designed to help other student groups on campus
          with promotional material creation, with a centralized focus on
          promotional videos. Members will not only design, script, film, and
          edit videos, but events throughout the year will be held to facilitate
          discussion about video editing techniques and skills. We welcome any
          members who want to hone their video production skills, and have a
          keen interest in marketing and outreach.
        </div>
      </div>
      <div id="team-container">
        <div id="team-header">Our Exec Team</div>
        <div id="team-members">
          <MemberCard
            name="Sai Panidarapu"
            role="Principal Founder and President"
            description="After having created several promotional videos for clubs at
                        the University of Alberta, Sai spearheaded the creation of
                        VIVID, and is currently leading the client acquisition and
                        outreach divisions."
            img={require("../Images/final_sai.jpg")}
          />
          <MemberCard
            name="Dayyan Muhammad"
            role="Co-Founder and Senior Vice President"
            description="Having designed websites for multiple clients, Dayyan has honed his interest at VIVID by leading the web design and social media divisions, and has a sharp eye for graphic design."
            img={require("../Images/d3.jpg")}
          />
          <MemberCard
            name="Hassaan Muhammad"
            role="Co-Founder and Vice President of Programming"
            description="Being an avid reader and literature enthusiast, Hasssan takes pride in leading the scriptwriting division at VIVID, and will always have you covered with awesome dialogue."
            img={require("../Images/1662746481987.jpg")}
          />
          <MemberCard
            name="Dawood Ahmed"
            role="Co-Founder and Vice President of Finance"
            description="With a passion for creating content, Dawood leads the video production division at VIVID and is always down to have a conversation about new video editing softwares."
            img={require("../Images/Background_1.png")}
          />
          <MemberCard
            name="Ashley Thomas"
            role="Vice President of External Affairs"
            description="As someone who grew up watching films and playing video games, video editing is one of the things that I enjoy devoting my time towards as it grants me the opportunity to create content that can make an impact towards how we interact with things or even how we perceive the world."
            img={require("../Images/IMG_1587.jpg")}
          />
        </div>
      </div>
    </div>
  );
}
